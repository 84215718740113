import React, { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { Link, useNavigate } from "react-router-dom";

import {
  getManageSubscriptionURL,
  orderSuccess,
  postCheckout,
  getCSShieldStats,
} from "../utils/api";
import { MainContext } from "./Main";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

// Product IDs
const MONTHLY_STRIPE_SUB_ID =
  process.env.NODE_ENV === "development"
    ? "price_1Q70zvIwJheCLZQ8yAqqQgWO"
    : "price_1QPhaTIwJheCLZQ8VlEKdWif";

type productDisplayProps = {
  setSessionId: React.Dispatch<React.SetStateAction<string>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  messagesChecked: number;
};
const ProductDisplay = ({
  setMessage,
  messagesChecked,
}: productDisplayProps) => {
  const context = useContext(MainContext);
  const navigate = useNavigate();

  const checkoutClick = (volumeLimit: number) => {
    onCheckout("subscribe", volumeLimit);
  };

  const onCheckout = async (checkOutType: string, volumeLimit: number) => {
    // Stripe checkout flow
    let res = null;
    // The below price_ids come from the Stripe dashboard
    if (checkOutType === "subscribe") {
      res = await postCheckout(
        MONTHLY_STRIPE_SUB_ID,
        "PROFANITY_FILTER",
        volumeLimit,
        null,
      );
    }

    if (res?.ok) {
      const resData = await res.json();
      if (resData === "User already a subscriber") {
        setMessage(
          "You are already a subscriber. Please refresh your browser or send us a message for help.",
        );
      } else if (resData?.url) {
        window.location.replace(resData.url);
      }
    } else {
      setMessage(
        "Something went wrong. Please try again or send us a message for help.",
      );
    }
  };

  const onLoginClick = () => {
    navigate("/login");
  };

  const onManageSubscriptionClick = async () => {
    const res = await getManageSubscriptionURL();
    if (res?.url) {
      window.location.replace(res.url);
    }
  };

  type PurchaseButtonProps = {
    volumeLimit: number;
  };
  const PurchaseButton: React.FC<PurchaseButtonProps> = ({ volumeLimit }) => {
    if (!context?.user?.email) {
      return (
        <Button
          className="purchase-button"
          variant="contained"
          onClick={onLoginClick}
        >
          Log in to subscribe
        </Button>
      );
    } else if (
      context?.user?.profanityMembershipActive ||
      context?.user?.profanityFreeTrialDaysRemaining == null
    ) {
      return null;
    } else {
      return (
        <>
          <Button
            className="purchase-button"
            id="subscribe"
            variant="contained"
            onClick={() => checkoutClick(volumeLimit)}
          >
            Select
          </Button>
        </>
      );
    }
  };

  const ContactUsButton = () => {
    return (
      <Button
        className="questions-btn"
        variant="contained"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          window.location.href = "mailto:support@thackd.com";
          e.preventDefault();
        }}
      >
        Contact Us
      </Button>
    );
  };

  return (
    <Styled>
      <div className="product">
        <div className="description">
          <Typography variant="h1" gutterBottom>
            Profanity Filter Pricing
          </Typography>
        </div>

        <div className="pricing-cards-container">
          {context?.user?.isProfanityUser && (
            <Card className="pricing-card">
              <Typography variant="body1" gutterBottom className="top-gap">
                {context?.user?.profanityMembershipActive && (
                  <span className="emphasize-text">Subscription Active</span>
                )}
                {!context?.user?.profanityMembershipActive && (
                  <>
                    Days until your free trial expires:{" "}
                    <span className="emphasize-text">
                      {context.user?.profanityFreeTrialDaysRemaining > 0
                        ? context.user?.profanityFreeTrialDaysRemaining
                        : "Free trial expired. Please select a package below."}
                    </span>
                  </>
                )}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                style={{ display: "flex", alignItems: "center" }}
              >
                Messages checked in last 4 weeks:{" "}
                <span
                  className="emphasize-text"
                  style={{ marginLeft: "0.25rem" }}
                >
                  {messagesChecked.toLocaleString()}
                </span>
                <Tooltip title="The number of your messages that have been checked for profanity by our integration in the last 4 weeks">
                  <HelpOutlineIcon className="help-icon" fontSize="small" />
                </Tooltip>
              </Typography>

              {context?.user?.profanityMembershipActive && (
                <Button
                  className="login-button"
                  variant="contained"
                  onClick={onManageSubscriptionClick}
                >
                  Manage Subscription
                </Button>
              )}
            </Card>
          )}

          <Card className="pricing-card">
            <Typography variant="body1" gutterBottom>
              Because our systems check every single message you receive through
              Intercom, our pricing is based on the volume of messages you
              receive each calendar month.
            </Typography>
            <Typography variant="body1" gutterBottom className="top-gap">
              All plans include:
            </Typography>
            <ul>
              <Typography variant="body1" gutterBottom>
                <li>30-day free trial</li>
                <li>Checking every email and instant message received</li>
                <li>Filtering of over 700 offensive words</li>
                <li>
                  AI-powered "toxic intent" algorithm reduces false positives
                </li>
                <li>Responsive support</li>
                <li>Cancel anytime</li>
              </Typography>
            </ul>

            <TableContainer component={Paper}>
              <Table className="table-container" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Monthly message volume </TableCell>
                    <TableCell align="right">Price / month</TableCell>
                    <TableCell align="center">Purchase</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Up to 500</TableCell>
                    <TableCell align="right">FREE</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>501 - 1,000</TableCell>
                    <TableCell align="right">$10</TableCell>
                    <TableCell align="center">
                      <PurchaseButton volumeLimit={1000} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>1,001 - 5,000</TableCell>
                    <TableCell align="right">$19</TableCell>
                    <TableCell align="center">
                      <PurchaseButton volumeLimit={5000} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>5001 - 10,000</TableCell>
                    <TableCell align="right">$29</TableCell>
                    <TableCell align="center">
                      <PurchaseButton volumeLimit={10000} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>10,001 - 25,000</TableCell>
                    <TableCell align="right">$39</TableCell>
                    <TableCell align="center">
                      <PurchaseButton volumeLimit={25000} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>25,001 - 50,000</TableCell>
                    <TableCell align="right">$79</TableCell>
                    <TableCell align="center">
                      <PurchaseButton volumeLimit={50000} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>50,001 - 100,000</TableCell>
                    <TableCell align="right">$149</TableCell>
                    <TableCell align="center">
                      <PurchaseButton volumeLimit={100000} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>100,001 - 150,000</TableCell>
                    <TableCell align="right">$249</TableCell>
                    <TableCell align="center">
                      <PurchaseButton volumeLimit={150000} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>150,001 - 200,000</TableCell>
                    <TableCell align="right">$349</TableCell>
                    <TableCell align="center">
                      <PurchaseButton volumeLimit={200000} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Additional Volume</TableCell>
                    <TableCell align="right">
                      $100 per extra 50,000 messages
                    </TableCell>
                    <TableCell align="center">
                      <ContactUsButton />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body1" gutterBottom className="top-gap">
              You will receive reminders when you are approaching your limit to
              allow you to increase your limit. Any messages over your limit
              will not be checked. Their delivery will not be affected.
            </Typography>
          </Card>
        </div>
      </div>
    </Styled>
  );
};

const SuccessDisplay = () => {
  return (
    <Styled>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription successful!</h3>
          <Typography variant="body1">
            Thank you! Your subscription was successful and we have sent a
            confirmation email to you.
          </Typography>
          <Typography variant="body1">
            You can manage your subscription any time by clicking the user
            avatar in the top right.
          </Typography>
          <div className="success-continue-btn">
            <Link to={"/"}>
              <Button variant="contained">Continue</Button>
            </Link>
          </div>
        </div>
      </div>
    </Styled>
  );
};

type messagePropsType = {
  message: string;
};
const Message = ({ message }: messagePropsType) => (
  <section>
    <Typography variant="body1">{message}</Typography>
  </section>
);

export default function PricingProfanity() {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [messagesChecked, setMessagesChecked] = useState(0);
  const context = useContext(MainContext);

  useEffect(() => {
    const onSuccess = async (newSessionId: string) => {
      // We don't need to provide a referral code for Stripe here because we provide it in the checkout session
      await orderSuccess(newSessionId, null);
      await context.refreshUser();
    };

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const newSessionId = query.get("session_id") || "";
      if (!success) {
        onSuccess(newSessionId);
        setSuccess(true);
      }
      setSessionId(newSessionId);
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled.");
    }
  }, [sessionId, success, context]);

  useEffect(() => {
    const getStats = async (userId: string) => {
      const stats = await getCSShieldStats(userId);
      setMessagesChecked(stats["num_conversation_parts_checked"]);
    };
    if (messagesChecked === 0 && context?.user) {
      getStats(context.user.id);
    }
  }, [context, messagesChecked]);

  if (!success && message === "") {
    return (
      <ProductDisplay
        setSuccess={setSuccess}
        setSessionId={setSessionId}
        setMessage={setMessage}
        messagesChecked={messagesChecked}
      />
    );
  } else if (success && sessionId !== "") {
    return <SuccessDisplay />;
  } else {
    return (
      <>
        <Message message={message} />
        <ProductDisplay
          setSuccess={setSuccess}
          setSessionId={setSessionId}
          setMessage={setMessage}
          messagesChecked={messagesChecked}
        />
      </>
    );
  }
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;

  .bold {
    font-weight: bold;
  }

  .success-continue-btn {
    margin-top: 20px;
  }

  .pricing-cards-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 2rem 0 1rem 0;
    gap: 2rem;

    .pricing-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      max-width: 700px;
      min-height: 150px;
      padding: 1rem;

      .login-button {
        margin: 2rem 0 0.5rem 0;
        width: 220px;
      }

      .purchase-button {
        width: 100px;
      }

      .questions-btn {
        width: 150px;
      }
    }
  }

  .top-gap {
    margin-top: 3rem;
  }

  .table-container {
    max-width: 500px;
  }

  .emphasize-text {
    font-weight: bold;
  }

  .help-icon {
    margin-left: 0.3rem;
  }
`;
