import React, { useContext, useRef, useState } from "react";
import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import EditIcon from "@mui/icons-material/Edit";

import { MainContext } from "./Main";
import { getManageSubscriptionURL, removeToken } from "../utils/api";
import { LOGO_PURPLE } from "../styles";
import { ISnackbarContent } from "../interfaces";
import thackdLogo from "../images/thackd-logo.png";

type Props = {
  onMenuClick: () => void;
};
const Header = ({ onMenuClick }: Props) => {
  const context = useContext(MainContext);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const refProfile = useRef(null);
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] = useState<ISnackbarContent>({
    severity: "success",
    title: "",
    body: "",
  });

  const Profile = () => {
    const handleLogout = () => {
      setMenuOpen(false);
      refProfile.current = null;
      removeToken();
      context.refreshUser();
      navigate("/");
    };

    const onManageSubscription = async () => {
      setMenuOpen(false);
      const res = await getManageSubscriptionURL();
      if (res?.url) {
        window.location.replace(res.url);
      }
    };

    const onEditCustomFields = () => {
      setMenuOpen(false);
      navigate("/custom-field-editor");
    };

    const onEditTaskAttributes = () => {
      setMenuOpen(false);
      navigate("/attribute-editor");
    };

    const handleSnackbarClose = (
      event: React.SyntheticEvent | Event,
      reason?: string,
    ) => {
      if (reason === "clickaway") {
        return;
      }
      setShowSnackbar(false);
    };

    return (
      <>
        <Button
          aria-controls={menuOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          onClick={() => setMenuOpen(true)}
        >
          <Avatar sx={{ bgcolor: LOGO_PURPLE }}>
            {context?.user?.email[0].toUpperCase()}
          </Avatar>
        </Button>
        <Menu
          anchorEl={refProfile.current}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {(context?.user?.asanaMembershipActive ||
            context?.user?.asanaFreeTrialActive) && (
            <MenuItem onClick={onEditCustomFields}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Asana Custom Field Editor" />
            </MenuItem>
          )}
          {(context?.user?.asanaMembershipActive ||
            context?.user?.asanaFreeTrialActive ||
            context?.user?.mondayMembershipActive ||
            context?.user?.mondayFreeTrialActive) && (
            <MenuItem onClick={onEditTaskAttributes}>
              <ListItemIcon>
                <PlaylistAddIcon />
              </ListItemIcon>
              <ListItemText primary="Task Attribute Editor" />
            </MenuItem>
          )}
          {(context?.user?.asanaMembershipActive ||
            context?.user?.profanityMembershipActive) && (
            <MenuItem onClick={onManageSubscription}>
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Subscription" />
            </MenuItem>
          )}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Log out</ListItemText>
          </MenuItem>
        </Menu>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarContent.severity}
            sx={{ width: "100%" }}
          >
            <AlertTitle>{snackbarContent.title}</AlertTitle>
            {snackbarContent.body}
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <Styled>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar color="inherit">
          <Toolbar>
            <Button onClick={onMenuClick}>
              <MenuIcon sx={{ color: "black" }} />
            </Button>
            <div className="header-logo">
              <Link to={"/"}>
                <img className={"logo-text"} src={thackdLogo} alt={"Thackd"} />
              </Link>
            </div>
            {!context?.user ? (
              <Link to={"/login"} className="link-no-underline">
                <Button className="login-btn" variant="text">
                  Log in
                </Button>
              </Link>
            ) : (
              <div ref={refProfile}>
                <Profile />
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </Styled>
  );
};

const Styled = styled.div`
  .header-logo {
    flex-grow: 1;

    .logo-text {
      margin: 10px 0 10px 0;
      width: 100%;
      max-width: 200px;
    }
  }

  .link-no-underline {
    text-decoration: none;
  }
`;

export default Header;
