import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";

import { MainContext } from "./Main";
import {
  getAsanaCustomFieldList,
  getCustomFieldList,
  getProfile,
  postSaveCustomFields,
} from "../utils/api";
import { ICustomField, IProfile } from "../interfaces";

const CustomFieldEditor = () => {
  const context = useContext(MainContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] = useState<string>("");
  const [selectedCustomFields, setSelectedCustomFields] = useState<
    ICustomField[]
  >([]);
  const [searchResults, setSearchResults] = useState<ICustomField[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [profileId, setProfileId] = useState<string | null>(
    useParams().profileId || null,
  );
  const [profile, setProfile] = useState<IProfile | null>(null);

  useEffect(() => {
    const getCustomFieldListAsync = async (localProfileId: string) => {
      const customFieldListResponse = await getCustomFieldList(localProfileId);
      setSelectedCustomFields(customFieldListResponse);

      setLoading(false);
    };

    const getProfileAsync = async (profileId: string) => {
      const profileResponse = await getProfile(profileId);
      setProfile(profileResponse.profile);
    };

    if (profileId === null) {
      if (context.user) {
        setProfileId(context.user.profileId);
        getCustomFieldListAsync(context.user.profileId);
      } else {
        navigate("/login");
      }
    } else {
      getProfileAsync(profileId);
      getCustomFieldListAsync(profileId);
    }
  }, [context.user, navigate, profileId]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleRemoveField = (fieldId: string) => {
    setSelectedCustomFields((prevFields) =>
      prevFields.filter((field) => field.gid !== fieldId),
    );
  };

  const handleAddField = (field: ICustomField) => {
    setSelectedCustomFields((prevFields) => [...prevFields, field]);
  };

  const handleSaveClick = async () => {
    setSaving(true);
    try {
      await postSaveCustomFields(selectedCustomFields, profileId as string);
      setSnackbarContent("Custom Fields list saved");
      setSnackOpen(true);
    } catch (e) {
      console.error(e);
      setSnackbarContent("Error saving custom fields");
      setSnackOpen(true);
    }
    setSaving(false);
  };

  const handleSearch = async () => {
    setSearching(true);
    try {
      const results = await getAsanaCustomFieldList(
        searchQuery,
        profileId as string,
      );
      setSearchResults(results);
    } catch (e) {
      console.error(e);
      setSnackbarContent("Error searching for custom fields");
      setSnackOpen(true);
    }
    setSearching(false);
  };

  return (
    <Styled>
      {!(
        profile?.asanaMembershipActive ||
        profile?.asanaFreeTrialActive ||
        context?.user?.asanaMembershipActive ||
        context?.user?.asanaFreeTrialActive
      ) ? (
        <>
          <Typography variant="h3" paragraph>
            This feature requires a paid plan or active free trial
          </Typography>

          <Typography variant="body1" paragraph>
            <Button variant="contained" href={`/asana-pricing/${profileId}/`}>
              Upgrade now
            </Button>
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h1">Custom Field Editor</Typography>
          <Typography variant="body1" paragraph>
            Here you can choose which Asana custom fields you can edit in
            Intercom when creating a task.
          </Typography>
          <Typography variant="body1" paragraph>
            Search for custom fields in Asana by name and add them to your
            Selected Custom Fields list. If you don't see the custom field
            you're looking for, you may need to add the project field to your
            Asana workspace's field library. More info in{" "}
            <a
              href="https://thackd.com/asana/asana-in-the-inbox#select-custom-fields"
              target="_blank"
              rel="noopener noreferrer"
            >
              our docs
            </a>
            .
          </Typography>

          {loading ? (
            <div className="loading-container">
              <CircularProgress />
            </div>
          ) : (
            <div className="content">
              <div className="search-container">
                <Typography variant="h3">Add Custom Fields</Typography>
                <TextField
                  label="Custom Field search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <LoadingButton
                  loading={searching}
                  variant="contained"
                  onClick={handleSearch}
                  className="search-button"
                >
                  Search
                </LoadingButton>
                <div className="search-results">
                  <Typography variant="h4" className="search-results-title">
                    Search Results
                  </Typography>
                  {searchResults.length === 0 ? (
                    <Typography variant="body1">No results found</Typography>
                  ) : (
                    searchResults.map((field) => (
                      <div key={field.gid} className="field-item">
                        <Typography variant="body1">{field.name}</Typography>
                        <Button
                          variant="contained"
                          onClick={() => handleAddField(field)}
                        >
                          Add
                        </Button>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="selected-fields-container">
                <Typography variant="h3">Selected Custom Fields</Typography>
                {selectedCustomFields.length === 0 ? (
                  <Typography variant="body1">
                    No custom fields selected.
                  </Typography>
                ) : (
                  selectedCustomFields.map((field) => (
                    <div key={field.gid} className="field-item">
                      <Typography variant="body1">{field.name}</Typography>
                      <Button
                        variant="contained"
                        onClick={() => handleRemoveField(field.gid)}
                      >
                        Remove
                      </Button>
                    </div>
                  ))
                )}
                <div className="button-container">
                  <LoadingButton
                    loading={saving}
                    variant="contained"
                    onClick={handleSaveClick}
                  >
                    Save
                  </LoadingButton>
                </div>
              </div>
            </div>
          )}
          <Snackbar
            open={snackOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            message={snackbarContent}
          />
        </>
      )}
    </Styled>
  );
};

const Styled = styled.div`
  .MuiTypography-h1 {
    margin-bottom: 1rem;
  }

  .italic {
    font-style: italic;
  }

  .content {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 2rem;

    .MuiTypography-h3 {
      margin-bottom: 1rem;
    }

    .MuiTypography-body1 {
      margin-bottom: 0.3rem;
    }

    .selected-fields-container {
      margin-left: 10rem;
    }

    .field-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.5rem;
    }

    .search-container {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;

      .search-button {
        margin-top: 1rem;
        margin-bottom: 3rem;
      }

      .search-results {
        margin-top: 1rem;

        .search-results-title {
          margin-bottom: 1rem;
        }
      }
    }

    .button-container {
      margin-top: 1rem;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    width: 500px;
    max-width: 100%;
    margin-top: 2rem;
  }
`;

export default CustomFieldEditor;
