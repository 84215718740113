import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function sortAlpha(a: string, b: string) {
  return a.localeCompare(b);
}

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function uppercaseString(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function calculatePrice(seats: number): number {
  const flatPrice = 10;
  const oneToFivePrice = 0;
  const sixToTenPrice = 5;
  const elevenToTwentyPrice = 4;
  const twentyOneToFiftyPrice = 3;
  const fiftyPlusPrice = 2;

  if (seats <= 5) {
    return flatPrice + seats * oneToFivePrice;
  } else if (seats <= 10) {
    return flatPrice + 5 * oneToFivePrice + (seats - 5) * sixToTenPrice;
  } else if (seats <= 20) {
    return (
      flatPrice +
      5 * oneToFivePrice +
      5 * sixToTenPrice +
      (seats - 10) * elevenToTwentyPrice
    );
  } else if (seats <= 50) {
    return (
      flatPrice +
      5 * oneToFivePrice +
      5 * sixToTenPrice +
      10 * elevenToTwentyPrice +
      (seats - 20) * twentyOneToFiftyPrice
    );
  } else {
    return (
      flatPrice +
      5 * oneToFivePrice +
      5 * sixToTenPrice +
      10 * elevenToTwentyPrice +
      30 * twentyOneToFiftyPrice +
      (seats - 50) * fiftyPlusPrice
    );
  }
}

export function calculateMondayPricePerSeat(seats: number): number {
  // Calculates the price per seat for monday.com based on the number of seats

  const oneToTenPrice = 10;
  const elevenToTwentyPrice = 29;
  const twentyOneToThirtyPrice = 49;
  const thirtyOneToFortyPrice = 69;
  const fortyOneToFiftyPrice = 89;
  const fiftyOneToSixtyPrice = 109;
  const sixtyOneToSeventyFivePrice = 129;
  const seventySixToHundredPrice = 169;
  const hundredOneToFiftyPrice = 199;
  const hundredFiftyOneToTwoHundredPrice = 249;
  const twoHundredOneToThreeHundredPrice = 299;
  const threeHundredOneToFiveHundredPrice = 399;
  const fiveHundredPlusPrice = 499;

  if (seats <= 10) {
    return oneToTenPrice / seats;
  } else if (seats <= 20) {
    return elevenToTwentyPrice / seats;
  } else if (seats <= 30) {
    return twentyOneToThirtyPrice / seats;
  } else if (seats <= 40) {
    return thirtyOneToFortyPrice / seats;
  } else if (seats <= 50) {
    return fortyOneToFiftyPrice / seats;
  } else if (seats <= 60) {
    return fiftyOneToSixtyPrice / seats;
  } else if (seats <= 75) {
    return sixtyOneToSeventyFivePrice / seats;
  } else if (seats <= 100) {
    return seventySixToHundredPrice / seats;
  } else if (seats <= 150) {
    return hundredOneToFiftyPrice / seats;
  } else if (seats <= 200) {
    return hundredFiftyOneToTwoHundredPrice / seats;
  } else if (seats <= 300) {
    return twoHundredOneToThreeHundredPrice / seats;
  } else if (seats <= 500) {
    return threeHundredOneToFiveHundredPrice / seats;
  } else {
    return fiveHundredPlusPrice / seats;
  }
}
