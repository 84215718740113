import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";

import { MainContext } from "./Main";
import {
  getAttributesList,
  getAvailableAttributesList,
  getProfile,
  postSaveAttributesList,
} from "../utils/api";
import { IProfile } from "../interfaces";

const AttributeEditor = () => {
  const context = useContext(MainContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] = useState<string>("");
  const [availableAttributesList, setAvailableAttributesList] = useState<
    string[]
  >([]);
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);
  const [profileId, setProfileId] = useState<string | null>(
    useParams().profileId || null,
  );
  const [profile, setProfile] = useState<IProfile | null>(null);

  useEffect(() => {
    const getAttributeListAsync = async (localProfileId: string) => {
      const attributesListResponse = await getAttributesList(localProfileId);
      if (attributesListResponse.length === 0) {
        setSelectedAttributes([]);
      } else {
        setSelectedAttributes(attributesListResponse);
      }

      const availableAttributesListResponse =
        await getAvailableAttributesList(localProfileId);
      setAvailableAttributesList(
        availableAttributesListResponse.sort((a: string, b: string) =>
          a.localeCompare(b),
        ),
      );
      setLoading(false);
    };

    const getProfileAsync = async (profileId: string) => {
      const profileResponse = await getProfile(profileId);
      setProfile(profileResponse.profile);
    };

    if (profileId === null) {
      if (context.user) {
        setProfileId(context.user.profileId);
        getAttributeListAsync(context.user.profileId);
      } else {
        navigate("/login");
      }
    } else {
      getProfileAsync(profileId);
      getAttributeListAsync(profileId);
    }
  }, [context.user, navigate, profileId]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleSaveClick = async () => {
    setSaving(true);
    try {
      await postSaveAttributesList(selectedAttributes, profileId as string);
      setSnackbarContent("Attributes list saved");
      setSnackOpen(true);
    } catch (e) {
      console.error(e);
      setSnackbarContent("Error saving attributes");
      setSnackOpen(true);
    }
    setSaving(false);
  };

  const handleAddAttribute = (attribute: string) => {
    setSelectedAttributes((prevAttributes) => [...prevAttributes, attribute]);
  };

  const handleRemoveAttribute = (attribute: string) => {
    setSelectedAttributes((prevAttributes) =>
      prevAttributes.filter(
        (selectedAttribute) => selectedAttribute !== attribute,
      ),
    );
  };

  return (
    <Styled>
      {!(
        profile?.asanaMembershipActive ||
        profile?.asanaFreeTrialActive ||
        profile?.mondayMembershipActive ||
        profile?.mondayFreeTrialActive ||
        context?.user?.asanaMembershipActive ||
        context?.user?.asanaFreeTrialActive ||
        context?.user?.mondayMembershipActive ||
        context?.user?.mondayFreeTrialActive
      ) ? (
        <>
          <Typography variant="h3" paragraph>
            This feature requires a paid plan or active free trial
          </Typography>

          <Typography variant="h5" paragraph>
            Upgrade now!
          </Typography>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button variant="contained" href={`/asana-pricing/${profileId}/`}>
              Upgrade Asana in the Inbox
            </Button>
            <Button
              variant="contained"
              href="https://monday.com/marketplace/listing/10000525/intercom#pricing"
            >
              Upgrade monday in the Inbox
            </Button>
          </div>
        </>
      ) : (
        <>
          <Typography variant="h1">Task Attribute Editor</Typography>
          <Typography variant="body1" paragraph>
            Here you can edit which attributes from Intercom are added to the
            task description in Asana/monday.
          </Typography>
          <Typography variant="body1" paragraph>
            On the left are the attributes you have currently selected to appear
            in task descriptions.
          </Typography>
          <Typography variant="body1" paragraph>
            On the right is a list of all attributes available in your Intercom
            workspace. Just add the ones you want and click save.
          </Typography>

          <Typography variant="body1" paragraph>
            <span className="bold">Tickets note:</span> if you do not see Ticket
            attributes in the list, please reauthorize our app in Intercom by
            clicking{" "}
            <a
              href="https://thackd.com/api/intercom_asana_install"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </Typography>

          {loading ? (
            <div className="loading-container">
              <CircularProgress />
            </div>
          ) : (
            <div className="content">
              <div className="input-container">
                <Typography variant="h3">Selected Attributes</Typography>
                {selectedAttributes.length === 0 ? (
                  <Typography variant="body1">
                    No attributes selected
                  </Typography>
                ) : (
                  selectedAttributes.map((attribute) => (
                    <div key={attribute} className="attribute-item">
                      <Button
                        variant="contained"
                        onClick={() => handleRemoveAttribute(attribute)}
                      >
                        Remove
                      </Button>
                      <Typography variant="body1">{attribute}</Typography>
                    </div>
                  ))
                )}
                <div className="button-container">
                  <LoadingButton
                    loading={saving}
                    variant="contained"
                    onClick={handleSaveClick}
                  >
                    Save
                  </LoadingButton>
                </div>
              </div>
              <div className="available-attributes-container">
                <Typography variant="h3">Available Attributes</Typography>
                {availableAttributesList.map((attribute) => (
                  <div key={attribute} className="attribute-item">
                    <Button
                      variant="contained"
                      onClick={() => handleAddAttribute(attribute)}
                    >
                      Add
                    </Button>
                    <Typography variant="body1">{attribute}</Typography>
                  </div>
                ))}
              </div>
            </div>
          )}
          <Snackbar
            open={snackOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            message={snackbarContent}
          />
        </>
      )}
    </Styled>
  );
};

const Styled = styled.div`
  .MuiTypography-h1 {
    margin-bottom: 1rem;
  }

  .italic {
    font-style: italic;
  }

  .bold {
    font-weight: bold;
  }

  .content {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 2rem;

    .MuiTypography-h3 {
      margin-bottom: 1rem;
    }

    .MuiTypography-body1 {
      margin-bottom: 0.3rem;
    }

    .available-attributes-container {
      margin-left: 10rem;
    }

    .attribute-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.5rem;
    }

    .button-container {
      margin-top: 1rem;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    width: 500px;
    max-width: 100%;
    margin-top: 2rem;
  }
`;

export default AttributeEditor;
