import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./App.css";
import Home from "./components/Home";
import About from "./components/About";
import Main from "./components/Main";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ErrorPage from "./error-page";
import ResetPassword from "./components/ResetPassword";
import AsanaInTheInbox from "./components/Products/AsanaInTheInbox";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import MondayInTheInbox from "./components/Products/MondayInTheInbox";
import ProfanityFilter from "./components/Products/ProfanityFilter";
import ExplicitImageBlocker from "./components/Products/ExplicitImageBlocker";
import Pricing from "./components/Pricing";
import PricingAsana from "./components/PricingAsana";
import PricingMonday from "./components/PricingMonday";
import Frame from "./components/Frame";
import PricingProfanity from "./components/PricingProfanity";
import AttributeEditor from "./components/AttributeEditor";
import CustomFieldEditor from "./components/CustomFieldEditor";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      errorElement: <ErrorPage />,
      children: [
        {
          errorElement: <ErrorPage />,
          children: [
            {
              index: true,
              element: <Home />,
            },
            {
              path: "login",
              element: <Login />,
            },
            {
              path: "signup",
              element: <SignUp />,
              children: [
                {
                  path: ":profileId/:appSigningUpFor",
                  element: <SignUp />,
                },
              ],
            },
            {
              path: "pricing",
              element: <Pricing />,
            },
            {
              path: "asana-pricing",
              element: <PricingAsana />,
              children: [
                {
                  path: ":profileId",
                  element: <PricingAsana />,
                },
              ],
            },
            {
              path: "monday-pricing",
              element: <PricingMonday />,
            },
            {
              path: "profanity-pricing",
              element: <PricingProfanity />,
            },
            {
              path: "reset-password",
              element: <ResetPassword />,
            },
            {
              path: "asana-in-the-inbox",
              element: <AsanaInTheInbox />,
            },
            {
              path: "monday-in-the-inbox",
              element: <MondayInTheInbox />,
            },
            {
              path: "profanity-filter",
              element: <ProfanityFilter />,
            },
            {
              path: "explicit-image-blocker",
              element: <ExplicitImageBlocker />,
            },
            {
              path: "attribute-editor",
              element: <AttributeEditor />,
              children: [
                {
                  path: ":profileId",
                  element: <AttributeEditor />,
                },
              ],
            },
            {
              path: "custom-field-editor",
              element: <CustomFieldEditor />,
              children: [
                {
                  path: ":profileId",
                  element: <CustomFieldEditor />,
                },
              ],
            },
            {
              path: "about",
              element: <About />,
            },
            {
              path: "terms-of-service",
              element: <TermsOfService />,
            },
            {
              path: "privacy-policy",
              element: <PrivacyPolicy />,
            },
          ],
        },
      ],
    },
    {
      path: "/frame",
      element: <Frame />,
      children: [
        {
          path: "monday-intercom-integration",
          element: <MondayInTheInbox />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <Helmet>
        <title>Top Intercom Integrations</title>
        <meta
          name="description"
          content="Intercom integrations to increase productivity and keep your agents safe. Offensive language and image blocking. Asana and monday.com integrations"
        />
        <meta property="og:title" content="Top Intercom Integrations" />
        <meta property="og:url" content="https://thackd.com" />
        <meta
          property="og:description"
          content="Intercom integrations to increase productivity and keep your agents safe. Offensive language and image blocking. Asana and monday.com integrations"
        />
      </Helmet>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
